import { Stack, Typography } from "@mui/material"
import Logo from "../../assets/erorex-Logo.png";
import MaazLogo from "../../assets/maaz-logo.png";
import Banner from "../../assets/invoice_banner_zr.png";

const ClientInfo = () => {
    return(
        <Stack
        sx={{ mb: 2, mt: 1 }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        spacing={3}>
        <img src={Logo} alt="logo" width={"250"} height={"70"} />

        <Stack>
          {/* <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
            ZR Communication
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Shop No 23, 1st Floor, Naaz Market, Liaqat Rd,
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Near Moti Masjid, Rawalpindi
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Cell No : 03205338888 Email info@erorex.com
          </Typography> */}
          <img src={MaazLogo} alt="logo" width={"250"} height={"70"} />
        </Stack>
      </Stack>
    )
}

// const ClientInfo = () => {
//     return(
//         <Stack
//         sx={{ mb: 2, mt: 1 }}
//         direction={"row"}
//         justifyContent={"space-between"}
//         alignItems={"end"}
//         spacing={3}>
//         <img src={Banner} alt="logo" width={"100%"} />
//       </Stack>
//     )
// }

export default ClientInfo